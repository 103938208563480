import gql from "graphql-tag"

export const contentPageWithSessionsPreview = gql`
    query getContentPageWithSessionsPreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        slug
                        databaseId
                        title
                        content
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        team_fields {
                            teams {
                                teamName
                                teamMembers {
                                    ... on Person {
                                        id
                                        title
                                        person_fields {
                                            image {
                                                sourceUrl
                                                title
                                            }
                                            role
                                            email: emailAddress
                                        }
                                    }
                                }
                            }
                        }
                        sidebar_calls {
                            sidebarCtas {
                                ... on CTA {
                                    id
                                    title
                                    cta_fields {
                                        link {
                                            title
                                            target
                                            url
                                        }
                                        description
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        person_ctas {
                            people {
                                ... on Person {
                                    id
                                    title
                                    person_fields {
                                        ctaDescription
                                        email: emailAddress
                                        role
                                        phone: telephoneNumber
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        sessions_fields {
                            sessions {
                                description
                                subject
                                title
                                venue {
                                    ... on Venue {
                                        id
                                        title
                                    }
                                }
                                link {
                                    url
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

// export const basicCollectionContentPreview = graphql`
//   fragment BasicCollectionContentPreview on Collection {
//     id
//     title
//     link
//     contentType {
//       node {
//         name
//       }
//     }
//     image: featuredImage {
//       sourceUrl
//       title
//     }
//   }
// `

// export const collectionContentPreview = graphql`
//   fragment CollectionContentPreview on Collection {
//     id
//     title
//     link
//     content
//     venue {
//       venue {
//         ...VenueMenuSettingsPreview
//       }
//     }
//     image: featuredImage {
//       sourceUrl
//       title
//     }
//     hero_fields {
//       image {
//         sourceUrl
//         title
//       }
//     }
//     page_introduction {
//       introduction
//     }
//     sub_title {
//       subTitle
//     }
//     collection_fields {
//       highlightedObjectsTitle
//       emuseumCta {
//         title
//         description
//         link {
//           url
//           title
//         }
//         buttonText
//       }
//       highlightedObjects {
//         title
//         description
//         image {
//           sourceUrl
//           title
//         }
//         link {
//           url
//           title
//         }
//       }
//     }
//   }
// `
