import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { contentPageWithSessionsPreview as PREVIEW_QUERY } from "../preview-queries/contentPageWithSessionsPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Subnavigation from "../components/Shared/Subnavigation"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import Content from "../components/Shared/Content"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import ArticleHeader from "../components/Shared/ArticleHeader"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import SessionLeaderList from "../components/Sessions/SessionLeaderList"
import SessionLeader from "../components/Sessions/SessionLeader"
import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateContentPageWithSessions = (props) => {
    const page = getContent(props, "page")

    const venue = venueForPage({ page: page, menus: props.data.menus })
    const venueChildPage = page.ancestors?.nodes && page.ancestors.nodes.slice(-1)[0].pageTemplate === "home-page"
    const venueFirstChildPage = page.parent && page.parent.pageTemplate === "home-page"
    const ancestorsCount = page.ancestors && page.ancestors.length

    let superTitle
    if (venueChildPage && ancestorsCount === 2) {
        superTitle = page.ancestors.nodes.slice(-2)[0].title
    } else if (!venueChildPage && page.ancestors?.nodes) {
        superTitle = page.ancestors.nodes.slice(-1)[0].title
    }

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaSmall title={superTitle} image={page.image.image}></HeroAreaSmall>

            <Main>
                <ContentWithSidebar>
                    <Content>
                        <ArticleHeader title={page.title} introduction={page.page_introduction.introduction} />

                        {page?.sessions_fields?.sessions && (
                            <SessionLeaderList>
                                {page?.sessions_fields?.sessions.map((session, index) => (
                                    <SessionLeader key={`session-${index}`} {...session} />
                                ))}
                            </SessionLeaderList>
                        )}
                    </Content>

                    <Sidebar>
                        {(page.children || page.parent) && !venueFirstChildPage && <Subnavigation sectionTitle={superTitle} page={page} />}

                        {page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}

                        {page.person_ctas.people && page.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar>
                </ContentWithSidebar>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ContentPageWithSessionsQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            id
            ...PageContent
            ...PageSubnav
            ...SidebarCtas
            ...PersonCtas
            ...SessionsContent
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateContentPageWithSessions)
